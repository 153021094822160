import React, {useEffect} from 'react';
import styled from '@emotion/styled';
import Image from 'gatsby-image';
import Layout from './layout';
import { graphql } from "gatsby";
import ReactMarkdown from 'react-markdown';
import Sticky from 'react-stickynode';

const Contenido = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    width: 95%auto;

    @media (min-width: 768px){
        display: grid;
        grid-template-columns: 2fr 1fr;
        column-gap: 5rem;
    }
    .textoContenido{
        text-align: justify;
    }
`;
const Sidebar = styled.aside`
    .categoria{
        font-size: 1.7rem;
        color: #444444;
        p{
            margin: 0;
        }
    }
    .autor{
        font-size: 2rem;
        margin-top: 2rem;
        border-radius: 2rem;
        background-color: #e7e7de;
        padding: 3rem;
        color: #222831;

        p{
            margin: 0;
        }
    }
`;



export const query = graphql`
    query($id: String!){
        allStrapiArticulos(filter: {id: {eq: $id}}) {
        nodes {
            titulo
            contenido
            published_at
            autor{
                nombre
            }
            categoria{
                nombre
            }
            imagen{
                sharp: childImageSharp{
                    fluid(maxWidth: 1200){
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        }
    }

`


const Articulos = ({data: {allStrapiArticulos: {nodes}}}) => {

    const {titulo, contenido, autor, imagen, published_at, categoria} = nodes[0];
    const fechaPub = published_at.slice(0,10);

    // conexxion con comentarios en GraphComment
    useEffect(() => {
        window.gc_params = {
            graphcomment_id: 'electronatural',
            fixed_header_height: 0,
        };

        (function() {
            var gc = document.createElement('script'); gc.type = 'text/javascript'; gc.async = true;
            gc.src = 'https://graphcomment.com/js/integration.js?' + Date.now();
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(gc);
        })();
    }, []);

    return (
        <Layout>
            <h1>{titulo}</h1>
            <Contenido>
                <main className="textoContenido">
                    <Image
                        fluid={imagen.sharp.fluid}
                    />
                    <ReactMarkdown             
                    >{contenido}</ReactMarkdown>
                    <div id="graphcomment"></div>
                </main>
                <Sticky
                    bottomBoundary='.textoContenido'
                >
                    <Sidebar>
                        <div className="autor">
                            <h3>Categoría: </h3>
                            <p>{categoria.nombre}</p>
                        </div>
                        <div className="autor">
                            <h3>Autor:</h3>
                            <p>{autor.nombre}</p>
                            <p>Fecha de publicación: </p>
                            <p>{fechaPub}</p>
                        </div>
                    </Sidebar>
                </Sticky>

            </Contenido>
        </Layout>
    );
}

export default Articulos;
